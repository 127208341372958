@font-face {
  font-family: "font-1";
  src: url(./assets/fonts/JosefinSans-ExtraLight.ttf);
}
@font-face {
  font-family: "font-2";
  src: url(./assets/fonts/JosefinSans-Regular.ttf);
}
@font-face {
  font-family: "font-3";
  src: url(./assets/fonts/JosefinSans-Bold.ttf);
}

$font-1: "font-1", sans-serif;
$font-2: "font-2", serif;
$font-3: "font-3", serif;

$color-1: #ff7b77;
$color-2: #ffd0c4;
$color-3: rgb(0, 22, 44);
$color-4: #fdeeee;
$black: rgb(40, 40, 40);
$white: rgb(253, 253, 253);
$shadow: rgb(219, 218, 218);

* {
  margin: 0;
  margin-bottom: 0.0rem;
  padding: 0;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;

  &::selection {
    background: $color-1;
  }
}
a,
a:visited {
  color: $color-3;
}

.btn {
  background: black;
  color: white;
  font-family: "font-3", serif;
  font-weight: bold;
}

.btn:hover {
  background: blue;
  color: #ccdfc3;
  font-family: "font-3", serif;
}

div {
  max-width: 2200px;
}

#main {
  margin-left: 4px;
  max-width: 2200px;
}

@media screen and (min-width: 640px) {
  #main {max-width: 2200px;}
}

