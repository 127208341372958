.navbar {
  display: block;
  width: 100%;
  height: 50px; /* same as a element line-height */
  background: #f2f0f1dd;
  z-index: 99;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.menu li {
  list-style: none;
  position: relative;
  margin-bottom: 0;
  vertical-align: middle;
}

.navbar .menu {
  margin-bottom: 0;
}

/* Initial menus should be inline-block so that they are horizontal */
.navbar .menu li,
.menu.horizontal li {
  display: inline-block;
  *display: inline;
  zoom: 1;
}

/* Submenus should still be display: block; */
.navbar .dropdown li,
.menu.horizontal .dropdown li {
  display: block;
}

.menu .item {
  display: block;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 500;
  padding: 0 15px;
  line-height: 55px;  /* same as a .navbar line-height */
  color: #222;
}

.menu > .item:first-child,
.menu > li:first-child > .item:first-child {
  padding-left: 10;
}

.menu > .item:last-child,
.menu > li:last-child > .item:last-child {
  padding-right: 10;
}

.dropdown .menu .item,
.menu.visible .item {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/*
.menu .button,
.menu button {
  margin-right: 25px;
}
*/

.menu a:hover,
.menu a:focus {
  color: #1EAEDB;
}

.menu .active a {
  color: red;
}

.menu .button:hover {
  color: inherit;
}

/* dropdown
----------------------------------------------- */

.dropdown {
  position: relative;
}

.dropdown ul {
  z-index: 100;
  position: absolute;
  left: 0;
  top: 3.5em;
  margin: 0em;
  display: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

/* ensure nested dropdowns use the full parent's width */
li.dropdown ul {
  min-width: 100%;
}

.dropdown li {
  z-index: 100;
  background-color: #fafafa;
  min-width: 150px;
  border: 1px solid #eee;
  border-bottom: none;
}

.dropdown ul .item {
  line-height: 40px;
}

.dropdown li:last-child {
  border-bottom: 1px solid #eee;
}

.dark .dropdown li {
  border-color: #1EAEDB;
}

.right.dropdown ul {
  left: auto;
  right: 0;
}

.hover.dropdown:hover > .item {
  color: #1EAEDB;
}

.dropdown.active ul,
.hover.dropdown:hover ul {
  display: block;
  visibility: visible;
}
