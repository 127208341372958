@use '../settings' as s;

.card-container3 {
  .fa-spinner {
    position: absolute;
    top: 40%;
    left: 49%;
    font-size: 1.5rem;
    color: s.$color-1;
  }  

tr{
  background: #FEF5E7;
}

  .card-left-3a {
    button,
    input[type="submit"] {
      height: 30px;
      min-width: 120px;
      background: #171717;
      color: #f7f5f5;
      font-family: "font-3", serif;
      cursor: pointer;
      transition: 0.2s;
      margin: 0;
      cursor: pointer;
      font-size: 0.9rem;
      border-radius: 20px 6px 20px 20px;
    }
  }

  .card-left-3 {
    span {
      color: black;
      font-family: "font-3", serif;
    }
    button,
    input[type="submit"] {
      width: 25px;
      align-items: center;
      background: #c4d5ff;
      font-family: "font-3", serif;
      cursor: pointer;
      transition: 0.2s;
      margin: 0;
      padding: 1px 4px;
      cursor: pointer;
      font-size: 1.1rem;
      border-radius: 20px 6px 20px 20px;
    }
  }
  .card-left-3c {
    button,
    input[type="submit"] {
      height: 30px;
      min-width: 150px;
      background: black;
      color: white;
      font-family: "font-2", serif;
      cursor: pointer;
      transition: 0.2s;
      margin: 0;
      padding: 1px 2px;
      cursor: pointer;
      font-size: 1.1rem;
      border-radius: 20px 6px 20px 20px;
    }
  }
}

.card-left-3d {
  button,
  input[type="submit"] {
    height: 30px;
    min-width: 150px;
    background: white;
    color: black;
    font-family: "font-2", serif;
    cursor: pointer;
    transition: 0.2s;
    margin: 0;
    padding: 1px 4px;
    cursor: pointer;
    font-size: 1.1rem;
    border-radius: 20px 6px 20px 20px;
  }
}

th {
  height: 30px;
  background: black;
  color: white;
  font-family: "font-3", serif;
}

hr {
  height: 3vh;
  width: auto;
  border-width: 0;
  color: #000;
  background-color: blue;
}

td,
th {
  border-color: #ccc #fff #fff #ccc;
  border-style: solid;
  border-width: 0.5px;
  text-align: center;
}

h3 {
  border: 2px solid sienna;
  color: blueviolet;
  text-align: center;
  max-width: 1000px;
}
