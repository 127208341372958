@use '../settings' as s;

.trending-container {
 
  #d1{background-color: #EECC4499;}

  #searchItems{width: 340px}

  h2 {
    border: 2px solid sienna;
    color: white;
    text-align: center;
    max-width: 1000px;
  }
  
  h4 {
    font-size: 1.1rem;
    color: s.$color-3;
    margin-bottom: 12px;
  }

  button,
  input[type="submit"] {
    width: 216px;    
    height: 48px;
    background: black;
    color: white;
    border: 2px solid white;
    border-radius: 20px 6px 20px 20px;
  }

  .grid-item1 {
    text-align: center;
  }
  .grid-item1 ul {
    display: flex;
  }
  
}
