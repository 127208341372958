@use '../settings' as s;

.card-container_h {

  button,
  input[type="submit"] {    
    width: 130px;    
    height: 30px;
    min-width: 130px;
    background: #17171709;
    color: #111111;
    font-family: "font-3", serif;
    cursor: pointer;
    transition: 0.2s;
    margin: 0;
    cursor: pointer;
    font-size: 0.9rem;
    border-radius: 20px 6px 20px 20px;
  }
}

.card-container_j {

  button,
  input[type="submit"] {    
    width: 130px;    
    height: 30px;
    min-width: 130px;
    background: #171717;
    color: #f7f5f5;
    font-family: "font-3", serif;
    cursor: pointer;
    transition: 0.2s;
    margin: 0;
    cursor: pointer;
    font-size: 0.9rem;
    border-radius: 20px 6px 20px 20px;
  }
}

.card-container_r {

  button,
  input[type="submit"] {    
    width: 130px;    
    height: 30px;
    min-width: 130px;
    background: #171717;
    color: #f7f5f5;
    font-family: "font-3", serif;
    cursor: pointer;
    transition: 0.2s;
    margin: 0;
    cursor: pointer;
    font-size: 0.9rem;
    border-radius: 20px 6px 20px 20px;
  }
}

.card-container_1 {

  .gIHijI {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    /* padding-right: 16px; */
  }

  button,
  input[type="submit"] {    
    width: 110px;    
    height: 25px;
    min-width: 110px;
    background: #171717;
    color: #f7f5f5;
    font-family: "font-3", serif;
    cursor: pointer;
    transition: 0.2s;
    margin: 0;
    cursor: pointer;
    font-size: 0.8rem;
    border-radius: 20px 6px 20px 20px;
  }
}

.popup-profil-container {
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  position: fixed;

 /*  @keyframes popup {
    to {
      backdrop-filter: blur(2px);
    }
  } */

  .modal {
    position: absolute;
    top: 10%;
    left: 45%;
    transform: translate(-100%);
    background: rgb(248, 248, 248);
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0 0 2px rgba(131, 130, 130, 0.356);
    overflow: auto;
    transform: scaleY(0);
    transform-origin: center;
    animation: modal .5s forwards;

    pre {
      font-size: 1.1rem;
      margin: 0;
      width: 1000px
    }

    span {
      color:red;
    }

    h3 {
      min-width: 500px;
      text-align: center;
    }
    h4 {
      min-width: 500px;
      text-align: center;
      border: 2px solid sienna;
      color: blueviolet;
    }

    @keyframes modal {
      to {
        transform: scale(1) translate(-50%);
      }
    }

    .cross {
      position: absolute;
      top: 5px;
      right: 25px;
      border: 2px solid red;
      transition: 0.1s;
      cursor: pointer;

      &:hover {
        transform: scale(1.07);
      }
    }
    
    li.simple {
      flex: 1;
      color: white;
      border-right: 80px solid white;
      text-align: center;  
    }    
    li.simple :last-child {
      border-right: none;
    }

    li.sup4ou2s4ouTrio {
      flex: 1;
      color: white;
      border-right: 100px solid white;
      text-align: center; 
    }    
    li.sup4ou2s4ouTrio :last-child {
      border-right: none;
    }

    li.multi {
      flex: 1;
      color: white;
      border-right: 20px solid white;
      text-align: center;      
    }
    li.multi :last-child {
      border-right: none;
    }

    li.tierce {
      flex: 1;
      color: white;
      border-right: 30px solid white;
      text-align: center;      
    }
    li.tierce :last-child {
      border-right: none;
    }

    li.quarte {
      flex: 1;
      color: white;
      border-right: 25px solid white;
      text-align: center;      
    }
    li.quarte :last-child {
      border-right: none;
    }

    li.quinte {
      flex: 1;
      color: white;
      border-right: 20px solid white;
      text-align: center;      
    }
    li.quinte :last-child {
      border-right: none;
    }

    ul {
      margin: 10px 0 0;
      max-height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: green;
      display: flex;
  justify-content: space-between;

      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background: s.$color-4;
      }
      &::-webkit-scrollbar-thumb {
        background: s.$color-1;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

  
    }
  }
}